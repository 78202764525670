<template>
    <div>
      <div class="text-right mb-3">
        <button
          class="btn btn-primary btn-sm"
          @click="addOwnerTap"
          title="إضافة مالك"
        >
          <fa icon="plus" class="ml-0 ml-sm-2" />
          <span class="d-none d-sm-inline-block">إضافة مالك</span>
        </button>
      </div>
  
      <div class="container clearfix">
        <div class="form-group float-right mt-3">
          <input
            type="text"
            class="form-control"
            placeholder="البحث"
            v-model="searchTerm"
          />
        </div>
      </div>
  
      <PanelList
        :items="finalOwners"
        title="الملاك"
        :isAllSelected="isAllSelected"
        @toggle-items-selection="toggleOwnersSelection()"
        @delete-selected="deleteSelectedOwners()"
        :canSelectAll="false"
      >
        <OwnerPanelItem
          v-for="(owner, i) in finalOwners"
          :key="i"
          :owner="owner"
        />
      </PanelList>
  
      <OwnerModal />
    </div>
  </template>
  
  <script>
  import PanelList from "@/components/general/panel-list";
  import OwnerPanelItem from "@/components/panel-items/owner";
  import OwnerModal from "@/components/modals/owner";
  import OwnersMixin from "@/mixins/owners";
  
  export default {
    mixins: [OwnersMixin],
  
    data () {
      return {
        searchTerm: ''
      }
    },
  
    methods: {
      addOwnerTap() {
        this.selectOwner(null);
        this.$bvModal.show("owner-modal");
      },
    },
  
    computed: {
      isAllSelected() {
        return this.owners.length == this.selectedOwners.length;
      },
  
      finalOwners () {
        if (!this.searchTerm) {
          return this.owners
        }
  
        return this.owners.filter(c => c.name.indexOf(this.searchTerm) > -1)
      }
    },
  
    components: {
      PanelList,
      OwnerPanelItem,
      OwnerModal,
    },
  };
</script>