<template>
  <li>
    <a href="#!" @click.prevent="showClientTap">
      <div class="row d-flex flex-wrap justify-content-between align-items-center px-2">
        <div
          class="panel-info text-center text-sm-right"
        >
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="client.selected"
              :value="true"
              @change.native="toggleClientSelection(client)"
            >
            </b-form-checkbox>
          </div>
          {{ client.name }}
        </div>

        <div :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`">
          <span class="text-muted">الجوال: </span> <span class="">{{ client.mobile }}</span>
        </div>

        <div v-if="client.ejarNumber" :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`">
          <span class="text-muted">رقم إيجار: </span> <span class="">{{ client.ejarNumber }}</span>
        </div>

        <div v-if="client.unpaidAmount > 0" :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`">
          <span class="text-muted">غير مدفوع: </span> <span :class="`p-1 rounded ${client.unpaidAmount >= 2000 ? 'bg-danger text-white' : ''}`">{{ client.unpaidAmount }} ر.س</span>
        </div>

        <div v-if="client.agreement" :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`">
          <span class="text-muted">بداية العقد: </span> <span :class="`p-1 rounded`">{{ client.agreementStart }}</span>
          <br />
          <span class="text-muted">نهاية العقد: </span> <span :class="`px-1 rounded ${agreementClass}`">{{ client.agreementEnd }}</span>
        </div>

        <div
          class="col-12 col-sm-6 col-md-2 panel-info text-center"
          @click.stop
        >
          <button
            v-if="client.agreement"
            class="btn btn-sm btn-warning ml-2"
            @click="$emit('show-agreement-tap', client)"
          >
            <fa icon="file-contract" class="ml-2" />
            <span>عرض العقد</span>
          </button>

          <button class="btn btn-sm btn-info" @click.stop="addNotificationTap">
            <fa icon="bell" />
          </button>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import ClientsMixin from "@/mixins/clients";

export default {
  mixins: [ClientsMixin],

  computed: {
    agreementClass() {
      if (this.client.agreementExpired) return "bg-danger text-white";

      if (this.client.agreementWarning) return "bg-warning";

      return "";
    },
  },

  methods: {
    showClientTap() {
      this.selectClient(this.client);
      this.$bvModal.show("client-modal");
    },

    addNotificationTap() {
      this.selectClient(this.client);
      this.$bvModal.show("notification-modal");
    },
  },

  props: ["client"],
};
</script>