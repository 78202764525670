<template>
    <li>
      <a href="#!" @click.prevent="showOwnerTap">
        <div class="row d-flex flex-wrap justify-content-between align-items-center px-2">
          <div
            class="panel-info text-center text-sm-right"
          >
            <div @click.stop class="d-inline">
              <b-form-checkbox
                class="d-inline pl-0"
                v-model="owner.selected"
                :value="true"
                @change.native="toggleOwnerSelection(owner)"
              >
              </b-form-checkbox>
            </div>
            {{ owner.name }}
          </div>
  
          <div :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`">
            <span class="text-muted">الجوال: </span> <span class="">{{ owner.mobile }}</span>
          </div>
        </div>
      </a>
    </li>
  </template>
  
  <script>
  import OwnersMixin from "@/mixins/owners";
  
  export default {
    mixins: [OwnersMixin],
  
    methods: {
      showOwnerTap() {
        this.selectOwner(this.owner);
        this.$bvModal.show("owner-modal");
      },
    },
  
    props: ["owner"],
  };
</script>