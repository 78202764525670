<template>
    <b-modal id="warehouse-item-modal">
        <template v-slot:modal-header="">
            <h5>المستودع</h5>
        </template>

        <template v-slot:default="">
            <div class="form-group text-right">
                <label class="small">الصنف:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    v-model="warehouseItem.name"
                />
            </div>

            <div class="form-group text-right">
                <label class="small">الكمية:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="number"
                    v-model="warehouseItem.quantity"
                />
            </div>

            <div class="form-group text-right">
                <label class="small">السعر:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="number"
                    v-model="warehouseItem.price"
                />
            </div>
        </template>

        <template v-slot:modal-footer="">
            <button
                type="submit"
                class="btn btn-primary"
                @click="submit"
            >حفظ</button>
            <a
                href="#"
                class="text-muted"
                @click.prevent="$bvModal.hide('warehouse-item-modal')"
            >إغلاق</a>
        </template>
    </b-modal>
</template>

<script>
import WarehouseItemsMixin from "@/mixins/warehouse-items";

export default {
    mixins: [WarehouseItemsMixin],

    data() {
        return {
            warehouseItem: {
                name: '',
                quantity: 0,
                price: 0
            }
        };
    },

    mounted() {
        this.buildWarehouseItem();
    },

    methods: {
        buildWarehouseItem() {
            console.log('building warehouse item: ', this.selectedWarehouseItem);
            if (this.selectedWarehouseItem) {
                console.log('selected warehouse item');
                this.warehouseItem.name = this.selectedWarehouseItem.name;
                this.warehouseItem.quantity = this.selectedWarehouseItem.quantity;
                this.warehouseItem.price = this.selectedWarehouseItem.price;
            } else {
                console.log('no selected warehouse item');
                this.warehouseItem.name = '';
                this.warehouseItem.quantity = 0;
                this.warehouseItem.price = 0;
            }
        },

        submit() {
            if (this.selectedWarehouseItem) {
                this.updateWarehouseItem(this.warehouseItem).then((res) => {
                    if (res.success) {
                        this.$bvModal.hide("warehouse-item-modal");
                        // this.showSwal(res)
                        // this.selectedProperty.maintenances.push(this.warehouseItem);
                    } else {
                        this.showSwal(res);
                    }
                });
            } else {
                this.saveWarehouseItem(this.warehouseItem).then((res) => {
                    if (res.success) {
                        this.$bvModal.hide("warehouse-item-modal");
                        // this.showSwal(res)
                        // this.selectedProperty.maintenances.push(this.warehouseItem);
                    } else {
                        this.showSwal(res);
                    }
                });
            }
        },
    },

    watch: {
        selectedWarehouseItem() {
            this.buildWarehouseItem();
        },
    },
};
</script>