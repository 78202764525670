import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveWarehouseItem: 'warehouseItems/saveRecord',
            updateWarehouseItem: 'warehouseItems/updateRecord',
            toggleWarehouseItemsSelection: 'warehouseItems/toggleRecordsSelection',
            toggleWarehouseItemSelection: 'warehouseItems/toggleRecordSelection',
            deleteSelectedWarehouseItems: 'warehouseItems/deleteSelectedRecords',
            clearWarehouseItemsCounter: 'warehouseItems/clearWarehouseItemsCounter'
        }),

        ...mapMutations({
            selectWarehouseItem: 'warehouseItems/SET_SELECTED_RECORD',
        })
    },

    computed: {
        ...mapGetters({
            warehouseItems: 'warehouseItems/records',
            selectedWarehouseItem: 'warehouseItems/selectedRecord',
            selectedWarehouseItems: 'warehouseItems/selectedRecords',
        })
    }
}