import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        owners: [],
        selectedOwner: null,
        searchTerm: null
    },

    getters: {
        owners: state => state.owners,
        selectedOwner: state => state.selectedOwner,
        selectedOwners: state => {
            return state.owners.filter(m => m.selected)
        },
        ownersFilter: state => {
            return state.owners.filter(c => c.name.indexOf(state.searchTerm) > -1)
        },
        searchTerm: state => state.searchTerm
    },

    mutations: {
        SET_OWNERS: (state, payload) => state.owners = payload,
        SET_SELECTED_OWNER: (state, payload) => state.selectedOwner = payload,
        SELECT_ALL: state => {
            state.owners.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.owners.forEach(m => m.selected = false)
        },
        SELECT_OWNER: (state, payload) => {
            state.owners[state.owners.indexOf(payload)].selected = true
        },

        DESELECT_OWNER: (state, payload) => {
            state.owners[state.owners.indexOf(payload)].selected = false
        },
        SET_SEARCH_TERM: (state, payload) => state.searchTerm = payload
    },

    actions: {
        async fetchOwners({ commit }) {
            let { data } = await axios.get('admin/owners')
            data.owners.forEach(m => m.selected = false)
            commit('SET_OWNERS', data.owners)

        },

        toggleOwnersSelection({ commit, getters, state }) {
            if (getters.selectedOwners.length < state.owners.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleOwnerSelection({ commit, state }, payload) {
            let currentState = state.owners[state.owners.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_OWNER', payload)
            } else {
                commit('DESELECT_OWNER', payload)
            }
        },

        async deleteSelectedOwners({ getters, dispatch }) {
            let ids = []
            getters.selectedOwners.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/owners', { params: { ids } })
            dispatch('fetchOwners')
            return data
        },

        async saveOwner({ commit, dispatch, getters }, payload) {
            let url = getters.selectedOwner ? `admin/owners/${getters.selectedOwner.id}` : `admin/owners`
            payload.role = 'owner'
            let { data } = await axios({
                method: getters.selectedOwner ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchOwners')
                commit('SET_SELECTED_OWNER', data.owner)
            }

            return data
        },

        // async saveAgentImage({ getters, dispatch }, image) {
        //     let formData = new FormData()
        //     formData.append('images[0]', image)
        //     formData.append('user_id', getters.selectedAgent.id)
        //     let { data } = await axios.post('admin/images', formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //     })

        //     if (data.success) {
        //         dispatch('fetchAgents')
        //     }

        //     return data
        // },

        // async exportAgents() {
        //     try {
        //         let { data } = await axios.get('admin/agentsExport', {
        //             responseType: 'arraybuffer',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Accept': 'application/pdf'
        //             }
        //         })

        //         const url = window.URL.createObjectURL(new Blob([data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'الموظفون.pdf'); //or any other extension
        //         document.body.appendChild(link);
        //         link.click();

        //         console.log(data)

        //         return data
        //     } catch (e) {
        //         console.log(e)
        //     }
        // }
    }
}