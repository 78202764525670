<template>
    <b-modal id="owner-modal">
      <template v-slot:modal-header="">
        <h5>المالك</h5>
      </template>
  
      <template v-slot:default="">
        <div class="form-group text-right">
          <label class="small">الاسم:</label>
          <input class="form-control" placeholder="الاسم" v-model="owner.name" />
          <div v-if="!owner.name" class="small text-red">الإدخال مطلوب.</div>
        </div>
  
        <div class="form-group mt-3 text-right">
          <label class="small">رقم الجوال:</label>
          <input
            class="form-control"
            placeholder="رقم الجوال"
            type="text"
            v-model="owner.mobile"
          />
          <div v-if="!owner.mobile" class="small text-red">الإدخال مطلوب.</div>
        </div>
  
        <!-- <div class="form-group mt-3 text-right">
          <label class="small">البريد الإلكتروني:</label>
          <input
            class="form-control"
            placeholder="البريد الإلكتروني"
            type="email"
            v-model="owner.email"
          />
          <div v-if="!owner.email" class="small text-red">الإدخال مطلوب.</div>
        </div> -->

        <div class="form-group mt-3 text-right">
          <label class="small">كلمة المرور:</label>
          <input
            class="form-control"
            placeholder="كلمة المرور"
            v-model="owner.password"
            type="password"
          />
          <div v-if="!owner.password && !selectedClient" class="small text-red">الإدخال مطلوب.</div>
        </div>
  
        <div class="form-group mt-3 text-right">
          <label class="small">تأكيد كلمة المرور:</label>
          <input
            class="form-control"
            placeholder="تأكيد كلمة المرور"
            v-model="owner.password_confirmation"
            type="password"
          />
          <div v-if="!owner.password_confirmation && !selectedClient" class="small text-red">الإدخال مطلوب.</div>
        </div>
      </template>
  
      <template v-slot:modal-footer="">
        <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
        <a
          href="#"
          class="text-muted"
          @click="$bvModal.hide('owner-modal')"
          >إغلاق</a
        >
      </template>
    </b-modal>
  </template>
  
  <script>
  import OwnersMixin from "@/mixins/owners";
  
  export default {
    mixins: [OwnersMixin],
  
    data() {
      return {
        owner: {
          name: null,
          // email: null,
          mobile: null,
          password: null,
          password_confirmation: null,
        },
      };
    },
  
    mounted() {
      this.buildOwner();
    },
  
    methods: {
      buildOwner() {
        if (this.selectedOwner) {
          // this.owner.name = this.owner.name;
          // this.owner.email = this.owner.email;
          // this.owner.mobile = this.owner.mobile;
          // this.owner.workAddress = this.owner.workAddress;
          // this.owner.idNumber = this.owner.idNumber;
          // this.owner.image = this.owner.image;
          Object.keys(this.owner).forEach((k) => (this.owner[k] = this.selectedOwner[k]));
        //   if (this.selectedOwner.image) {
        //     this.owner.image.link = this.selectedOwner.image.link
        //   }
  
        } else {
          Object.keys(this.owner).forEach((k) => (this.owner[k] = null));
        }
      },
      submit() {
        this.saveOwner(this.owner).then((res) => {
          if (res.success) {
            // this.showSwal(res)
            // if (this.$refs.idCardImage.files[0]) {
            //   this.saveClientImage(this.$refs.idCardImage.files[0]).then(
            //     (res2) => {
            //       if (res2.success) this.$bvModal.hide("client-modal");
            //     }
            //   );
            // } else {
              this.showSwal(res);
              this.$bvModal.hide("owner-modal");
            // }
          } else {
            this.showSwal(res);
          }
        });
      },
  
    //   updateImage(e) {
    //     this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0]);
    //   },
    },
  
    computed: {
    //   getImage () {
    //     return this.client.image ? this.client.image.link : require('@/assets/images/image-placeholder.png')
    //   }
    },
  
    watch: {
      selectedOwner() {
        this.buildOwner();
      },
    },
  };
</script>