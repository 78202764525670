import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveOwner: 'owners/saveOwner',
            // saveOwnerImage: 'owners/saveOwnerImage',
            toggleOwnersSelection: 'owners/toggleOwnersSelection',
            toggleOwnerSelection: 'owners/toggleOwnerSelection',
            deleteSelectedOwners: 'owners/deleteSelectedOwners',
            // exportOwners: 'owners/exportOwners'
        }),

        ...mapMutations({
            selectOwner: 'owners/SET_SELECTED_OWNER',
            ownersSetSearchTerm: 'owners/SET_SEARCH_TERM'
        })
    },

    computed: {
        ...mapGetters({
            owners: 'owners/owners',
            selectedOwner: 'owners/selectedOwner',
            selectedOwners: 'owners/selectedOwners',
            ownersSearchTerm: 'owners/searchTerm',
            ownersFilter: 'owners/ownersFilter'
        })
    }
}