import Vue from 'vue'
import Vuex from 'vuex'
// import axios from '@/axios'

import auth from './auth'
import clients from './clients'
import properties from './properties'
import categories from './categories'
import maintenances from './maintenances'
import ads from './ads'
import requests from './requests'
import agents from './agents'
import notifications from './notifications'
import settings from './settings'
import agreements from './agreements'
import discounts from './discounts'
import commissions from './commissions'
import hashmanager from './hashmanager'
import leaseRequests from './leaseRequests'
import bills from './bills'
import executionRequests from './execution-requests'
import warehouseItems from './warehouse-items'
import owners from './owners'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: false,
        lang: 'ar',
        showSideMenu: true
    },

    getters: {
        isLoading: state => state.isLoading,
        lang: state => state.lang,
        showSideMenu: state => state.showSideMenu
    },

    mutations: {
        SET_IS_LOADING: (state, payload) => state.isLoading = payload,
        TOGGLE_SIDE_MENU: state => state.showSideMenu = !state.showSideMenu
    },

    actions: {
        init({ dispatch }) {
            console.log('init function')
            dispatch('settings/fetchSettings');
            dispatch('settings/fetchFrontImages');
            dispatch('clients/fetchClients');
            dispatch('agents/fetchAgents');
            dispatch('properties/fetchProperties');
            dispatch('categories/fetchCategories');
            dispatch('ads/fetchAds');
            dispatch('maintenances/fetchMaintenances');
            dispatch('requests/fetchRequests');
            dispatch('agreements/fetchAgreements');
            dispatch('discounts/fetchDiscounts');
            dispatch('commissions/fetchCommissions');
            dispatch('leaseRequests/fetchRecords');
            dispatch('bills/fetchRecords');
            dispatch('executionRequests/fetchRecords');
            dispatch('warehouseItems/fetchRecords');
            dispatch('owners/fetchOwners');
        },


    },

    modules: {
        auth,
        clients,
        properties,
        categories,
        maintenances,
        ads,
        requests,
        agents,
        notifications,
        settings,
        agreements,
        discounts,
        commissions,
        hashmanager,
        leaseRequests,
        bills,
        executionRequests,
        warehouseItems,
        owners
    }
})