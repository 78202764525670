import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        records: [],
        selectedRecord: null,
    },

    getters: {
        records: state => state.records,
        selectedRecord: state => state.selectedRecord,
        selectedRecords: state => {
            return state.records.filter(m => m.selected)
        },
    },

    mutations: {
        SET_RECORDS: (state, payload) => state.records = payload,
        SET_SELECTED_RECORD: (state, payload) => state.selectedRecord = payload,
        SELECT_ALL: state => {
            state.records.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.records.forEach(m => m.selected = false)
        },
        SELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = true
        },

        DESELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchRecords({ commit }) {
            let { data } = await axios.get('admin/executionRequests')
            data.requests.forEach(m => m.selected = false)
            commit('SET_RECORDS', data.requests)
        },

        toggleRecordsSelection({ commit, getters, state }) {
            if (getters.selectedRecords.length < state.records.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleRecordSelection({ commit, state }, payload) {
            let currentState = state.records[state.records.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_RECORD', payload)
            } else {
                commit('DESELECT_RECORD', payload)
            }
        },

        async deleteSelectedRecords({ getters, dispatch }) {
            let ids = []
            getters.selectedRecords.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/executionRequests', { params: { ids } })
            dispatch('fetchRecords')
            return data
        },

        async saveRecord({ dispatch, getters, rootState }, payload) {
            if (payload.user && payload.user.id) {
                payload.user_id = payload.user.id
            } else if (rootState.users.users.length > 0) {
                payload.user_id = rootState.users.users[0].id
            }

            let formData = new FormData()

            for (const [key, value] of Object.entries(payload)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let url = getters.selectedRecord ? `admin/executionRequests/${getters.selectedRecord.id}` : `admin/executionRequests`
            let { data } = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchRecords')
            }

            return data
        },

        // async clearBillsCounter({ rootState }) {
        //     let { data } = await axios.post('admin/billsCounter')

        //     rootState.auth.user.billsCounter = 0

        //     return data
        // }
    }
}