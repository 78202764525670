<template>
  <b-modal id="agreement-modal">
    <template v-slot:modal-header="">
      <h5>العقد</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group text-right">
        <label class="small">البداية:</label>

        <b-form-datepicker
          v-model="selectedAgreement.start"
          locale="ar"
        ></b-form-datepicker>
      </div>

      <div class="form-group text-right">
        <label class="small">النهاية:</label>
        <b-form-datepicker
          v-model="selectedAgreement.end"
          locale="ar"
        ></b-form-datepicker>
      </div>

      <div class="form-group text-right">
        <label class="small">قيمة العقد:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.totalAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">الكهرباء:</label>
        <input
          class="form-control"
          placeholder=""
          type="text"
          v-model="selectedAgreement.electricity"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">الماء:</label>
        <input
          class="form-control"
          placeholder=""
          type="text"
          v-model="selectedAgreement.water"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">المبالغ المدفوعة:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.paidAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">المبالغ غير المدفوعة:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.unpaidAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small ml-2">طلب من محكمة التنفيذ:</label>
        <b-form-checkbox
          class="d-inline pl-0 mr-1"
          :value="true"
          v-model="selectedAgreement.isCourtRequest"
        >
        </b-form-checkbox>
      </div>

      <div class="form-group text-right">
        <label class="small">مدة العقد:</label>
        <select
          name="durationType"
          id="duration_type"
          class="form-control"
          v-model="selectedAgreement.durationType"
        >
          <option
            v-for="(type, index) in durationTypes"
            :key="index"
            :value="type.value"
          >
            {{ type.name }}
          </option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">نوع الدفعات:</label>
        <select
          name="paymentType"
          id="duration_type"
          class="form-control"
          v-model="selectedAgreement.paymentType"
          @change="paymentTypeChanged"
        >
          <option
            v-for="(type, index) in paymentTypes"
            :key="index"
            :value="type.value"
          >
            {{ type.name }}
          </option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">رقم العقد في إيجار:</label>
        <input
          class="form-control"
          placeholder=""
          type="text"
          v-model="selectedAgreement.ejarNumber"
        />
      </div>

      <button type="submit" class="btn btn-primary btn-block" @click="submit">
        حفظ
      </button>

      <hr />

      <div v-for="i in paymentsCount" :key="i" class="form-group text-right">
        <label v-if="paymentsCount > 1" class="small"
          >{{ paymentTitles[i] }}:</label
        >
        <label v-else class="small">الدفعة:</label>

        <b-form-datepicker
          v-model="selectedAgreement['payment' + i]"
          locale="ar"
        ></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input
              type="number"
              v-model="selectedAgreement['paid' + i]"
              class="form-control"
              @keyup="paidChanged"
            />
          </div>
          <div class="col">
            <label class="small">تاريخ الدفع</label>

            <b-form-datepicker
              v-model="selectedAgreement['paymentDate' + i]"
              locale="ar"
              placeholder="حدد التاريخ"
            ></b-form-datepicker>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="small">المتبقي</label>

            <input
              type="number"
              v-model="selectedAgreement['unpaid' + i]"
              class="form-control"
              @keyup="unpaidChanged"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="small">الكهرباء</label>
            <input
              type="text"
              v-model="selectedAgreement['electricity' + i]"
              class="form-control"
              @keyup="electricityChanged"
            />
          </div>
          <div class="col">
            <label class="small">الماء</label>

            <input
              type="text"
              v-model="selectedAgreement['water' + i]"
              class="form-control"
              @keyup="waterChanged"
            />
          </div>
        </div>

        <hr />
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a href="#" class="text-muted" @click="$bvModal.hide('agreement-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import AgreementsMixin from "@/mixins/agreements";

export default {
  mixins: [AgreementsMixin],

  data() {
    return {
      paymentTitles: {
        1: "الدفعة الأولى",
        2: "الدفعة الثانية",
        3: "الدفعة الثالثة",
        4: "الدفعة الرابعة",
        5: "الدفعة الخامسة",
        6: "الدفعة السادسة",
        7: "الدفعة السابعة",
        8: "الدفعة الثامنة",
        9: "الدفعة التاسعة",
        10: "الدفعة العاشرة",
        11: "الدفعة الحادية عشرة",
        12: "الدفعة الثانية عشرة",
      },
      durationTypes: [
        { name: "شهري", value: "monthly" },
        { name: "ربع سنوي", value: "quarterly" },
        { name: "نصف سنوي", value: "semiannually" },
        { name: "سنوي", value: "yearly" },
      ],
      paymentTypes: [
        { name: "شهري", value: "monthly", count: 12 },
        { name: "دفعة واحدة", value: "1", count: 1 },
        { name: "دفعتان", value: "2", count: 2 },
        { name: "ثلاث دفعات", value: "3", count: 3 },
        { name: "أربع دفعات", value: "4", count: 4 },
        { name: "ست دفعات", value: "6", count: 6 },
      ],
    };
  },

  methods: {
    submit() {
      this.updateAgreement().then((res) => {
        if (res.success) {
          this.$bvModal.hide("agreement-modal");
        }
      });
    },

    paymentTypeChanged() {
      const paymentType = this.selectedAgreement.paymentType;
      this.selectedAgreement.payment1 = this.selectedAgreement.start;

      for (let i = 2; i <= 12; i++) {
        this.selectedAgreement["payment" + i] = null;
      }

      if (paymentType === "1") {
        this.clearAmounts(2);
        return;
      }

      if (paymentType === "2") {
        this.selectedAgreement.payment2 = this.moment(
          this.moment(String(this.selectedAgreement.payment1))
        )
          .add(6, "months")
          .locale("en")
          .format("Y-MM-DD");

        this.clearAmounts(3);
        return;
      }

      if (paymentType === "3") {
        this.selectedAgreement.payment2 = this.moment(
          this.moment(String(this.selectedAgreement.payment1))
        )
          .add(4, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment3 = this.moment(
          this.moment(String(this.selectedAgreement.payment2))
        )
          .add(4, "months")
          .locale("en")
          .format("Y-MM-DD");

        this.clearAmounts(4);
        return;
      }

      if (paymentType === "4") {
        this.selectedAgreement.payment2 = this.moment(
          this.moment(String(this.selectedAgreement.payment1))
        )
          .add(3, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment3 = this.moment(
          this.moment(String(this.selectedAgreement.payment2))
        )
          .add(3, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment4 = this.moment(
          this.moment(String(this.selectedAgreement.payment3))
        )
          .add(3, "months")
          .locale("en")
          .format("Y-MM-DD");

        this.clearAmounts(5);
        return;
      }

      if (paymentType === "6") {
        this.selectedAgreement.payment2 = this.moment(
          this.moment(String(this.selectedAgreement.payment1))
        )
          .add(1, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment3 = this.moment(
          this.moment(String(this.selectedAgreement.payment2))
        )
          .add(1, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment4 = this.moment(
          this.moment(String(this.selectedAgreement.payment3))
        )
          .add(1, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment5 = this.moment(
          this.moment(String(this.selectedAgreement.payment4))
        )
          .add(1, "months")
          .locale("en")
          .format("Y-MM-DD");
        this.selectedAgreement.payment6 = this.moment(
          this.moment(String(this.selectedAgreement.payment5))
        )
          .add(1, "months")
          .locale("en")
          .format("Y-MM-DD");

        this.clearAmounts(7);
        return;
      }

      if (paymentType === "monthly") {
        for (let i = 2; i <= 12; i++) {
          this.selectedAgreement["payment" + i] = this.moment(
            this.moment(String(this.selectedAgreement.payment1))
          )
            .add(i, "months")
            .locale("en")
            .format("Y-MM-DD");
        }
        return;
      }
    },

    clearAmounts(from) {
      for (let i = from; i <= 12; i++) {
        this.selectedAgreement["unpaid" + i] = 0;
        this.selectedAgreement["electricity" + i] = 0;
        this.selectedAgreement["water" + i] = 0;
      }

      this.paidChanged();
      this.unpaidChanged();
      this.electricityChanged();
      this.waterChanged();
    },

    paidChanged() {
      let totalpaid = 0;
      for (let i = 1; i <= this.paymentsCount; i++) {
        totalpaid += parseFloat(this.selectedAgreement["paid" + i]);
      }
      this.selectedAgreement.paidAmount = totalpaid;
    },
    
    unpaidChanged() {
      let totalUnpaid = 0;
      for (let i = 1; i <= this.paymentsCount; i++) {
        totalUnpaid += parseFloat(this.selectedAgreement["unpaid" + i]);
      }
      this.selectedAgreement.unpaidAmount = totalUnpaid;
    },

    electricityChanged() {
      let totalElectricity = 0;
      for (let i = 1; i <= this.paymentsCount; i++) {
        totalElectricity += parseFloat(
          this.selectedAgreement["electricity" + i]
        );
      }
      this.selectedAgreement.electricity = totalElectricity;
    },

    waterChanged() {
      let totalWater = 0;
      for (let i = 1; i <= this.paymentsCount; i++) {
        totalWater += parseFloat(this.selectedAgreement["water" + i]);
      }
      this.selectedAgreement.water = totalWater;
    },
  },

  mounted() {
    this.paymentTypeChanged();
  },

  computed: {
    paymentsCount() {
      return this.paymentTypes.find(
        (type) => type.value === this.selectedAgreement.paymentType
      ).count;
    },
  },

  // watch: {
  //   'selectedAgreement.start': (val) => {
  //     console.log(val)
  //     console.log(this.moment(val).add('1', 'year'))
  //     // this.selectedAgreement.end = this.moment(val).add('1', 'year')
  //   }
  // }
};
</script>