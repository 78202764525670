<template>
    <div>
        <div class="text-right">
            <button class="btn btn-primary btn-sm" @click="addWarehouseItemTap">
                <fa icon="plus" class="ml-2" />
                <span>إضافة صنف</span>
            </button>
        </div>
      <PanelList
        class="mt-3"
        :items="warehouseItems"
        title="المستودع"
        :isAllSelected="isAllSelected"
        @toggle-items-selection="toggleWarehouseItemsSelection()"
        @delete-selected="deleteSelectedWarehouseItems()"
      >
        <WarehouseItemPanelItem
          v-for="(warehouseItem, i) in warehouseItems || []"
          :key="i"
          :warehouseItem="warehouseItem"
        />
      </PanelList>
  
      <WarehouseItemModal />
    </div>
  </template>
  
  <script>
  import PanelList from "@/components/general/panel-list";
  import WarehouseItemPanelItem from "@/components/panel-items/warehouse-item";
  import WarehouseItemModal from "@/components/modals/warehouse-item";
  import WarehouseItemsMixin from '@/mixins/warehouse-items';
  
  export default {
    mixins: [WarehouseItemsMixin],
  
    methods: {
      addWarehouseItemTap () {
        this.selectWarehouseItem(null)
        this.$bvModal.show('warehouse-item-modal')
      },
    },
  
    computed: {
      isAllSelected () {
        return this.warehouseItems.length == this.selectedWarehouseItems.length
      }
    },
  
    components: {
      PanelList,
      WarehouseItemPanelItem,
      WarehouseItemModal,
    },
  };
  </script>