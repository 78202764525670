import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            updateProperty: 'properties/updateProperty',
            saveProperty: 'properties/saveProperty',
            deleteImage: 'properties/deleteImage',
            uploadImages: 'properties/uploadImages',
            addRenting: 'properties/addRenting',
            addOwner: 'properties/addOwner',
            removeOwner: 'properties/removeOwner',
            deleteAgreement: 'properties/deleteAgreement',
            deleteProperty: 'properties/deleteProperty'
        }),

        ...mapMutations({
            selectProperty: 'properties/SELECT_PROPERTY'
        })
    },

    computed: {
        ...mapGetters({
            properties: 'properties/properties',
            selectedProperty: 'properties/selectedProperty'
        })
    }
}